/*@jsxRuntime classic @jsx React.createElement @jsxFrag React.Fragment*/
import {useMDXComponents as _provideComponents} from "@mdx-js/react";
import React from "react";
function _createMdxContent(props) {
  const _components = Object.assign({
    p: "p"
  }, _provideComponents(), props.components);
  return React.createElement(React.Fragment, null, React.createElement(_components.p, null, "Düz bir alana inşa edilerek tonozlarla yükseltilmesi nedeniyle mimari açıdan Türkiye’deki tek örnektir. İki yüzyıl gladyatör dövüşleri ve diğer oyunlar için kullanılan yapı, daha sonra farklı amaçlarla kullanılmıştır. 84 metre uzunluğunda ve 63 metre genişliğinde bir alanı kaplar. Yaklaşık on bin kişi seyirci kapasitesi bulunur. Sahne binası, üç katlıdır; üç adet giriş kapısı ve koridorla birbirinden ayrılan dört ana mekândan oluşur."), "\n", React.createElement(_components.p, null, "Latinlerin İstanbul'u işgali üzerine kurulan İznik Devleti sırasında, savaşlarda ölen askerlerin topluca bu tiyatronun dehlizlerine gömüldüğü tespit edilmiştir. Tiyatronun bulunduğu alan Osmanlı döneminde bir süre seramik fırını olarak kullanılmış; seramik fırınları işlevlerini yitirdikten sonra, yapıya ait blokların devşirilmesi ve çevredeki konutlarda kullanılması son yüzyıllara kadar devam etmiştir."));
}
function MDXContent(props = {}) {
  const {wrapper: MDXLayout} = Object.assign({}, _provideComponents(), props.components);
  return MDXLayout ? React.createElement(MDXLayout, props, React.createElement(_createMdxContent, props)) : _createMdxContent(props);
}
export default MDXContent;
